import styled from "styled-components";
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants";

export const HeadingContainerWrapper = styled.div`
    position: relative;
    height: 100vh;
    left: calc((9.375rem + (100vw - (70rem + 9.375rem * 2)) / 2) * -1);
    right: 0;
    top: -15rem;
    width: 100vw;
    background: ${props =>
        props.backgroundImage ? `url(${props.backgroundImage})` : ""};
    background-size: cover;
    background-position: center;
    z-index: -5;

    @media only screen and (max-width: 70rem) {
        left: -calc(14.75rem)
    }

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        top: -7.25rem;
        left: -6.25rem;
        height: 29.375rem;
        align-items: flex-end;
        margin-top: -5.5rem;
        padding-bottom: 3.3125rem;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        left: -1.5rem;
        height: 35rem;
        margin-top: -3.5rem;
    }
`

export const BackgroundImage = styled.img`
    position: absolute;
    inset: 0;
    z-index: -20;
    width: 100vw;
    height: 100%;
    object-fit: cover;
    object-position: center;
`

export const HeadingContainer = styled.div`
    height: calc(100vh - 6.5rem);
    max-height: 39rem;
    width: 70%;
    max-width: 70rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0 auto;
    padding: 0 9.375rem;
    z-index: 10;

    @media only screen and (max-width: 1350px) {
        padding: 0 6.25rem;
        height: 100%;
        width: calc(100% - 12.5rem);
        margin-bottom: 6.75rem;
    }

    @media only screen and (max-width: 767px) {
        width: calc(100% - 3rem);
        padding: 0 1.5rem;
        margin-bottom: 5rem;
    }
`;

export const HeadingMainText = styled.h1`
    margin: 0;
    font-weight: bold;
    font-size: 4rem;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        margin-top: -0.25rem;
        font-size: 2.5rem;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        font-size: 2rem;
        line-height: 2.25rem;
        margin-top: -3.75rem;
    }
`;

export const HeadingDescription = styled.p`
    font-weight: 200;
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--main-font-color);
    margin-top: 2.5rem;
    max-width: 43.75rem;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        margin-top: 1.5rem;
    }
`;

export const BlurOnImage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background: linear-gradient(0deg, #2b0934 0%, rgba(43, 9, 52, 0.4) 100%);
    -webkit-transform: translate3d(0, 0, 0);
    z-index: -5;

    @media only screen and (min-width: 1700px) {
        height: calc(100% + 0.3125rem);
    }

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        width: 100vw;
        background: linear-gradient(
            0deg,
            #2b0934 2%,
            rgba(43, 9, 52, 0.4) 100%
        );
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        margin-left: 0;
    }
`;
