import React, { useMemo } from "react";
import {
    HeadingContainer,
    HeadingMainText,
    HeadingDescription,
    HeadingContainerWrapper,
    BlurOnImage,
    BackgroundImage,
} from "./TextTitleSection.style";

const TextTitleSection = ({ title, description, backgroundImage }) => {
    const generatedDescription = useMemo(() => {
        const firstParagraph = description.split("<br/>")[0]; // firstParagraph always exists
        const secondParagraph = description.split("<br/>")[1];

        return (
            <HeadingDescription>
                {firstParagraph}
                {secondParagraph && (
                    <>
                        <br />
                        <br />
                        {secondParagraph}
                    </>
                )}
            </HeadingDescription>
        );
    }, [description]);

    return (
        <HeadingContainerWrapper>
            {backgroundImage && (
                <BackgroundImage 
                    src={backgroundImage}
                    alt="hero image"
                    fetchPriority="high" 
                    loading="eager" 
                />
            )}
            {backgroundImage && (
                <BlurOnImage />
            )}
            <HeadingContainer>
                <HeadingMainText>{title}</HeadingMainText>
                {generatedDescription}
            </HeadingContainer>
        </HeadingContainerWrapper>
    );
};

export default TextTitleSection;
